import React, { useState } from "react"
import DefaultLayout from "../layouts/defaultLayout"
import firebase from "gatsby-plugin-firebase"
import styled from "styled-components"
import { Link } from "gatsby"
import SEO from "../components/seo"

const MainContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 0.5rem;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 2rem;
  }
`

const Login = () => {
  const [email, setEmail] = useState()
  const [loading, setLoading] = useState(false)

  const login = e => {
    e.preventDefault()
    setLoading(true)
    firebase
      .firestore()
      .collection(process.env.GATSBY_FIRESTORE_USERS)
      .doc(email)
      .get()
      .then(function(doc) {
        if (doc.exists) {
          console.log("exists")
          signUp()
          alert("Check your email for a login link.")
          setEmail("")
        } else {
          setLoading(false)
          alert("Account does not exist")
        }
      })
      .catch(error => {
        setLoading(false)
        alert(error)
        return false
      })
  }

  const signUp = () => {
    var actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url: process.env.GATSBY_FIREBASE_URL,
      // This must be true.
      handleCodeInApp: true,
    }

    firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(function() {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email)
      })
      .catch(function(error) {
        setLoading(false)
        console.log(error)
      })
  }

  return (
    <DefaultLayout>
      <SEO title="Login" />
      <div>
        <div className=" max-w-md mx-auto">
          <h1 className="text-3xl font-medium mb-4 text-gray-800 text-center">
            Login
          </h1>
          <h2 className="text-center text-gray-600 font-medium mb-1">
            Welcome back!
          </h2>
          <h3 className="mb-8 text-gray-600 text-center">
            We dont like passwords and we think you don't either. Login once
            with a magic email link and never have to worry about passwords
            again!
          </h3>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <form
              onSubmit={login}
              class="w-full max-w-sm flex items-center border-b border-teal-500 py-2 bg-gray-200 px-4 rounded"
            >
              <input
                class="appearance-none bg-transparent border-none w-full text-gray-800 mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="text"
                onChange={e => setEmail(e.target.value)}
                placeholder="example@example.com"
                aria-label="Full name"
              />
              <button
                class="flex-shrink-0 bg-green-500 hover:bg-green-700 border-green-500 hover:border-green-700 text-sm border-4 text-white py-1 px-2 rounded"
                type="submit"
                disabled={loading}
              >
                Login
              </button>
            </form>
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: "4rem",
            marginBottom: "1rem",
          }}
        >
          <h3 className="text-gray-600">
            Don't have an account yet? Start your climate journey today!
          </h3>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Link to="/mainform" style={{ textDecoration: "none" }}>
              <div
                className="button homepageButton"
                style={{
                  width: "9rem",
                  marginTop: "1rem",
                  color: "white",
                  textAlign: "center",
                  textDecoration: "none",
                }}
              >
                <p
                  style={{
                    fontSize: "0.7rem",
                    fontWeight: 700,
                    textDecoration: "none",
                  }}
                >
                  GET STARTED
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default Login
